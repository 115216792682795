<template>
  <div>

    <v-container style="margin-bottom: 50px">
      <center>
        <h1 style="font-family: LexiaBold,'Khmer OS Muol Light' !important;font-weight: normal !important;">{{
            $t('requestUpdate')
          }}</h1>
      </center>
      <v-row>
        <v-col cols="12" md="5" sm="5">

          <v-row>
            <v-col cols="12">
              <h1 style="font-family: LexiaBold,'Khmer OS Muol Light' !important;font-weight: normal !important;">
                {{ $t('chooseLocationBelow') }}</h1>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="d-flex"
                   :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
              <v-autocomplete v-model="params.provinceId"
                              :items="provinceOptionList"
                              outlined
                              :dense="dense"
                              rounded
                              hide-details
                              hide-no-data
                              :rules="selectRules"
                              item-text="label"
                              item-value="value"
                              :label="$t('chooseProvince')"
                              search-input
                              required
              >

                <template slot="item" slot-scope="{item}">
                  {{ item.label }}
                </template>

              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="12" class="d-flex"
                   :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
              <v-autocomplete v-model="params.districtId"
                              :items="districtOptionList"
                              outlined
                              :dense="dense"
                              rounded
                              hide-details
                              hide-no-data
                              :rules="selectRules"
                              item-text="label"
                              item-value="value"
                              :label="$t('chooseDistrict')"
                              search-input
                              required
              >

                <template slot="item" slot-scope="{item}">
                  {{ item.label }}
                </template>

              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="d-flex"
                   :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
              <v-autocomplete v-model="params.communeId"
                              :items="communeOptionList"
                              outlined
                              :dense="dense"
                              rounded
                              hide-details
                              hide-no-data
                              :rules="selectRules"
                              item-text="label"
                              item-value="value"
                              :label="$t('chooseCommune')"
                              search-input
                              required
              >

                <template slot="item" slot-scope="{item}">
                  {{ item.label }}
                </template>

              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" md="12" class="d-flex"
                   :style="$vuetify.breakpoint.mobile ? 'justify-content: center;padding-top:20px' : 'justify-content: center'">
              <v-autocomplete v-model="params.villageId"
                              :items="villageOptionList"
                              outlined
                              :dense="dense"
                              rounded
                              hide-details
                              hide-no-data
                              :rules="selectRules"
                              item-text="label"
                              item-value="value"
                              :label="$t('chooseVillage')"
                              search-input
                              required
              >

                <template slot="item" slot-scope="{item}">
                  {{ item.label }}
                </template>

              </v-autocomplete>
            </v-col>

          </v-row>
        </v-col>
        <v-col cols="12" md="7" sm="7">
          <h2 style="font-family: LexiaBold,'Khmer OS Muol Light' !important;font-weight: normal !important;">{{
              $t("findResult")
            }}</h2>
          <p style="text-align: left;cursor: pointer" v-for="doc in result" :key="doc._id">

            <span v-if="$i18n.locale!='en'">
            <b>{{ doc.code }} : {{ doc.name }} </b> ភូមិ
            {{ doc.villageDoc && doc.villageDoc.name || "" }}
            ឃុំ/សង្កាត់ {{ doc.communeDoc && doc.communeDoc.name || "" }} ស្រុក/ខ័ណ្ឌ
            {{ doc.districtDoc && doc.districtDoc.name || "" }}
            រាជធានី/ខេត្ត {{ doc.provinceDoc && doc.provinceDoc.name || "" }}
              </span>
            <span v-if="$i18n.locale==='en'">
            <b>{{ doc.code }} : {{ doc.latinName || doc.name }} </b>,
              {{ doc.villageDoc && doc.villageDoc.latinName || doc.villageDoc.name || "" }} Village,
              {{ doc.communeDoc && doc.communeDoc.latinName || doc.communeDoc.name || "" }} Commune,
              {{ doc.districtDoc && doc.districtDoc.latinName || doc.districtDoc.name || "" }} District,
              {{ doc.provinceDoc && doc.provinceDoc.latinName || doc.provinceDoc.name || "" }} Province
              </span>
          </p>
          <div v-if="result.length===0">No Data Found</div>
          <v-row v-if="result.length>0">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.totalLessThan60"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('totalLessThan60')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.femaleLessThan60"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('femaleLessThan60')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.totalOver60"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('totalOver60')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.femaleOver60"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('femaleOver60')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.totalDisability"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('totalDisability')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.femaleDisability"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('femaleDisability')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.equityCard1"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('equityCard1')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.equityCard2"
                  :rules="requireInputNum"
                  type="number"
                  :label="$t('equityCard2')"
                  persistent-hint
                  required
                  :suffix="$t('person')"
                  :dense="dense"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                  v-model="dataObj.note"
                  :rules="requireInputNum"
                  :label="$t('note')"
                  persistent-hint
                  required
                  :dense="dense"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-col>
        <v-col cols="12" :md="$vuetify.breakpoint.mobile ? '12' : '6'" sm="6" style="padding: 0px !important;"
               class="d-flex flex-row justify-center" v-for="sDoc in service"
               :key="sDoc.serviceId">
          <ul :class="sDoc.value==='ដំណើរការល្អធម្មតា' ? 'custom-module-green1' : sDoc.value==='ដំណើរការមិនសូវល្អ' ? 'custom-module-orange1'  : 'custom-module-red1' "
              style="text-align: center;color: black;">
            <li style="color: black">

              <v-list-item style="padding: 0px !important;">
                <v-list-item-icon style="margin: 0px !important;">
                  <img style="width: 100px;height: 100px;"
                       :src="getServiceURLById(sDoc.serviceId)"/>
                </v-list-item-icon>

                <v-list-item-content style="justify-content: center">
                  <span v-if="$i18n.locale==='en'">
                       {{
                      getServiceById(sDoc.serviceId, 'en')
                    }}<br>Example ៖ {{ getServiceNoteById(sDoc.serviceId) }}
                  </span>
                  <span v-if="$i18n.locale!='en'">
                       {{
                      getServiceById(sDoc.serviceId)
                    }}<br>ឧទាហរណ៍ ៖ {{ getServiceNoteById(sDoc.serviceId) }}

                  </span>


                  <v-radio-group v-model="sDoc.value">
                    <v-radio
                        v-for="n in actionOption"
                        :key="n.value"
                        :label="n.label"
                        :value="n.value"
                    >
                      <template v-slot:label>

                        <img style="width: 60px;height: 30px;" v-if="n.value==='មិនទាន់មាន'"
                             src="@/assets/1.png"/>
                        <img style="width: 60px;height: 30px;"
                             v-if="n.value==='ដំណើរការមិនសូវល្អ'" src="@/assets/2.png"/>
                        <img style="width: 60px;height: 30px;"
                             v-if="n.value==='ដំណើរការល្អធម្មតា'" src="@/assets/3.png"/>
                        <span style="padding-left: 10px">{{ $t(n.label) }}</span>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-list-item-content>

                <!--                <v-list-item-action style="margin: 0px !important;">
                                  <img style="width: 60px;height: 30px;transform: rotate(270deg)" v-if="sDoc.value==='មិនទាន់មាន'"
                                       src="@/assets/1.png"/>
                                  <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                       v-if="sDoc.value==='ដំណើរការមិនសូវល្អ'" src="@/assets/2.png"/>
                                  <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                       v-if="sDoc.value==='ដំណើរការល្អធម្មតា'" src="@/assets/3.png"/>
                                </v-list-item-action>-->
              </v-list-item>

              <!--                      <div style="float: left">
                                      <img style="width: 100px;height: 100px;"
                                           :src="getServiceURLById(sDoc.serviceId)"/>
                                    </div>

                                    <div style="float: right">
                                      <img style="width: 60px;height: 30px;transform: rotate(270deg)" v-if="sDoc.value==='មិនទាន់មាន'"
                                           src="@/assets/1.png"/>
                                      <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                           v-if="sDoc.value==='ដំណើរការមិនសូវល្អ'" src="@/assets/2.png"/>
                                      <img style="width: 60px;height: 30px;transform: rotate(270deg)"
                                           v-if="sDoc.value==='ដំណើរការល្អធម្មតា'" src="@/assets/3.png"/>
                                    </div>
                                    <span style="font-family: Khmer OS Muol Light;width: 350px">{{
                                        getServiceById(sDoc.serviceId)
                                      }}<br>{{ getServiceEnById(sDoc.serviceId) }}
                                    </span>-->
            </li>
            <br>

          </ul>

        </v-col>
        <v-col cols="12" sm="12" md="12">
          <center>
            <h1 v-if="snackbarSuccess">{{ $t('requestSuccess') }}</h1>
            <v-btn
                class="ma-2"
                style="padding: 20px"
                block
                @click="submitRequest"
                color="indigo"
                dark
                x-large
            >
              {{ $t('requestEdit') }}
            </v-btn>
          </center>
        </v-col>

      </v-row>

      <v-snackbar
          v-model="snackbar"
          timeout="2000"
          color="red accent-2"
      >
        {{ erroorMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="blue"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>

  </div>

</template>

<script>

import MyMixin from "@/mixins/mixin"

import axios from "axios";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {Constants} from "../libs/constant"

export default {
  name: "EditData",
  mixins: [MyMixin],
  components: {},
  data() {
    return {
      index: null,
      model: null,
      search: "",
      intro: "",
      erroorMessage: "",
      dense: true,
      snackbar: false,
      snackbarSuccess: false,
      provinceOptionList: [],
      districtOptionList: [],
      communeOptionList: [],
      villageOptionList: [],
      service: [],
      serviceList: [],
      actionOption: Constants.actionOption,
      requireInputNum: [
        v => !!v || v === 0 || 'Please Input Data',
      ],
      selectRules: [
        v => !!v || 'Please Choose one',
      ],
      result: [],
      params: {
        provinceId: "",
        districtId: "",
        communeId: "",
        villageId: "",
      },
      dataObj: {
        villageId: "",
        code: "",
        service: [],
        totalLessThan60: "",
        femaleLessThan60: "",

        totalOver60: "",
        femaleOver60: "",
        totalDisability: "",
        femaleDisability: "",
        equityCard1: "",
        equityCard2: "",
        note: "",
        branchId: ""
      }
    };
  },
  mounted() {
    this.onResize();
  },
  watch: {
    /* videoLink(val) {
       let vm = this;
       vm.videoEmbedCode = "";
       if (val && val.split("youtu.be").length === 2) {
         vm.videoEmbedCode = val.replace("youtu.be", "youtube.com/embed");
       } else if (val && val.split("/watch?v=").length > 0) {
         vm.videoEmbedCode =
             "https://www.youtube.com/embed/" +
             val.split("/watch?v=")[1].split("&list=")[0].split("&t=")[0];
       }
     },*/
    "$i18n.locale"() {
      let vm = this;
      vm.fetchProvince();
    },
    "params.provinceId"(val) {
      this.fetchDistrict(val);
    },
    "params.districtId"(val) {
      this.fetchCommune(val);
    },
    "params.communeId"(val) {
      this.fetchVillage(val);
    },
    "params.villageId"() {
      this.fetchAssociationByGeoOrName();
    }
  },
  methods: {
    submitRequest() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.requestUpdate;
      vm.dataObj.branchId = Constants.branchId;
      vm.dataObj.service = vm.service;
      return new Promise((resolve, reject) => {
        axios.post(url, vm.dataObj, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.result = [];
                vm.snackbarSuccess = true;
                setTimeout(function () {
                  vm.snackbarSuccess = false;
                }, 2000)
                vm.$router.push({name: "success"});
              } else {
                vm.erroorMessage = "សូមបំពេញ ក្នុងបរិយាយ អោយបានច្បាស់";
                vm.snackbar = true;
              }
            },
            error => {
              reject(error);

            }
        );
      });
    },
    handleSearch() {
      this.fetchAssociationByGeoOrName();
    },
    gotoAssociation(id) {
      let vm = this;
      vm.$router.push({
        name: "Association", params: {id: id}
      })
    },
    fetchProvince() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getProvinceAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.provinceOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    fetchDistrict(provinceId) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getDistrictAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          provinceId: provinceId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.districtOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    fetchCommune(districtId) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getCommuneAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          districtId: districtId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.communeOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    fetchVillage(communeId) {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getVillageAss;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          communeId: communeId,
          language: vm.$i18n.locale
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.villageOptionList = res.data.data || [];
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    getServiceById(id, language) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return language == 'en' ? (sDoc && sDoc.labelEn || "") : (sDoc && sDoc.label || "");
    },
    getServiceURLById(id) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return sDoc && sDoc.url || "";
    },
    getServiceEnById(id) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return sDoc && sDoc.labelEn || "";
    },
    getServiceNoteById(id) {
      let sDoc = this.serviceList.find((d) => d.value === id);
      return sDoc && sDoc.note || "";
    },
    fetchService() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getService;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.serviceList = res.data.data || []
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    fetchAssociationByGeoOrName() {
      let vm = this;
      vm.service = [];
      let url = Constants.baseURL + Constants.api.getAssociationByGeoOrName;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          districtId: vm.params.districtId || "",
          communeId: vm.params.communeId || "",
          villageId: vm.params.villageId || "",
          search: vm.search || "",
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.result = res.data.data && res.data.data.content || [];
                if (vm.result.length > 0) {
                  vm.dataObj.villageId = vm.result[0].villageId || "";
                  vm.dataObj.code = vm.result[0].code || "";
                  vm.dataObj.totalOver60 = vm.result[0].totalOver60 || 0;
                  vm.dataObj.totalLessThan60 = vm.result[0].totalLessThan60 || 0;
                  vm.dataObj.femaleOver60 = vm.result[0].femaleOver60 || 0;
                  vm.dataObj.femaleLessThan60 = vm.result[0].femaleLessThan60 || 0;
                  vm.dataObj.totalDisability = vm.result[0].totalDisability || 0;
                  vm.dataObj.femaleDisability = vm.result[0].femaleDisability || 0;
                  vm.dataObj.equityCard1 = vm.result[0].equityCard1 || 0;
                  vm.dataObj.equityCard2 = vm.result[0].equityCard2 || 0;
                  if (vm.result[0].service && vm.result[0].service.length > 0) {
                    vm.service = vm.result[0].service;
                  } else {
                    vm.service = vm.serviceList.map((d) => {
                      let newD = {};
                      newD.serviceId = d.serviceId;
                      newD.value = "មិនទាន់មាន";
                      return newD;
                    })
                  }

                }
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight};
    },

  },
  created() {
    let vm = this;
    vm.fetchProvince();
    vm.fetchService();
  },
};
</script>


<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.member_counter {
  padding: 73px 0px 73px;
  background-image: -webkit-linear-gradient(0deg, lightblue 0%, #244a93 100%);
}

.changeColor:hover {
  color: darkgoldenrod;
  opacity: 1;
  cursor: pointer;
  elevation: 20deg;
}

.ten_news_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0px;
  padding: 10px 0;
  border-bottom: 1px solid #f4f4f4;
}

.program :hover {
  elevation: 30deg;
  color: darkgoldenrod;
}

.imgZoom :hover {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoomActive {
  transition: transform .2s;
  transform: scale(1.2);
}

.imgZoom1 :hover {
  transition: transform .20s;
  transform: scale(1.05);
}

.imgUp :hover {
  transition: transform .2s;
  margin-top: -10px;
}

.centered {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centeredTitle {
  position: absolute;
  top: 107%;
  left: 50%;
  transform: translate(-50%, 0%);

}

.changeOnHover :hover {
  background: lightgreen;
  color: white;
}

.img-circle {
  border-radius: 50%;
}

.row {
  margin: 0px !important;
}


ul.custom-module {
}

ul.custom-module li {
  maring: 5px;
  padding: 50px;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module li:hover {
  background: rgba(0, 59, 108, 0.8);
  border-radius: 20px;
  transform: scale(1);
  border: none;
  color: white;

}

ul.custom-module li.active-module {
  background: rgba(0, 59, 108, 0.8);
  border-radius: 20px;
  transform: scale(1);
  border: none;

}

i.v-icon:hover {
  color: white;
}


ul.custom-module {
}

ul.custom-module-green li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px green;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-green1 li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  width: 430px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px green;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-orange li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;

  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px orange;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-orange1 li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  width: 430px !important;

  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px orange;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-red li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  height: 180px !important;
  width: 370px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px red;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module-red1 li {
  maring: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 25px;
  width: 430px !important;
  display: inline-block;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  border: 2px solid green;
  border-left: solid 10px red;
  /*background: rgba(255, 255, 255, 0.5);*/
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;

}

ul.custom-module li:hover {
  background: yellowgreen;
  border-radius: 20px;
  transform: scale(1);
  border: none;
  color: white;

}

ul.custom-module li.active-module {
  background: rgba(0, 59, 108, 0.8);
  border-radius: 20px;
  transform: scale(1);
  border: none;

}

i.v-icon:hover {
  color: white;
}


</style>
